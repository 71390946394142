.background {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: #EFBC9B;
    overflow: hidden;
    z-index: -1;
  }
  
  .background span {
    width: 2vmin;
    height: 2vmin;
    border-radius: 2vmin;
    backface-visibility: hidden;
    position: absolute;
    animation: move;
    animation-duration: 24;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
  
  
  .background span:nth-child(0) {
    color: #d6d6d6;
    top: 47%;
    left: 93%;
    animation-duration: 249s;
    animation-delay: -85s;
    transform-origin: -1vw 20vh;
    box-shadow: 4vmin 0 0.8804514346116019vmin currentColor;
  }
  .background span:nth-child(1) {
    color: #a6a6a6;
    top: 27%;
    left: 82%;
    animation-duration: 9s;
    animation-delay: -188s;
    transform-origin: 4vw -3vh;
    box-shadow: -4vmin 0 1.3518406329132608vmin currentColor;
  }
  .background span:nth-child(2) {
    color: #fcfaff;
    top: 71%;
    left: 41%;
    animation-duration: 122s;
    animation-delay: -52s;
    transform-origin: 13vw -3vh;
    box-shadow: -4vmin 0 1.0438512741115102vmin currentColor;
  }
  .background span:nth-child(3) {
    color: #fcfaff;
    top: 37%;
    left: 1%;
    animation-duration: 77s;
    animation-delay: -85s;
    transform-origin: 19vw -8vh;
    box-shadow: 4vmin 0 1.298144251409246vmin currentColor;
  }
  .background span:nth-child(4) {
    color: #a6a6a6;
    top: 44%;
    left: 45%;
    animation-duration: 109s;
    animation-delay: -207s;
    transform-origin: -13vw 15vh;
    box-shadow: -4vmin 0 0.9941043269745933vmin currentColor;
  }
  .background span:nth-child(5) {
    color: #fcfaff;
    top: 85%;
    left: 95%;
    animation-duration: 44s;
    animation-delay: -89s;
    transform-origin: 19vw -23vh;
    box-shadow: 4vmin 0 0.5378281862132084vmin currentColor;
  }
  .background span:nth-child(6) {
    color: #d6d6d6;
    top: 54%;
    left: 67%;
    animation-duration: 257s;
    animation-delay: -173s;
    transform-origin: 10vw -3vh;
    box-shadow: -4vmin 0 0.6500015246706947vmin currentColor;
  }
  .background span:nth-child(7) {
    color: #fcfaff;
    top: 32%;
    left: 10%;
    animation-duration: 107s;
    animation-delay: -126s;
    transform-origin: 21vw 9vh;
    box-shadow: 4vmin 0 1.4768468966717823vmin currentColor;
  }
  .background span:nth-child(8) {
    color: #a6a6a6;
    top: 77%;
    left: 15%;
    animation-duration: 193s;
    animation-delay: -144s;
    transform-origin: 13vw -21vh;
    box-shadow: 4vmin 0 1.325685740460087vmin currentColor;
  }
  .background span:nth-child(9) {
    color: #a6a6a6;
    top: 27%;
    left: 57%;
    animation-duration: 228s;
    animation-delay: -145s;
    transform-origin: 9vw 1vh;
    box-shadow: 4vmin 0 1.3995581617197064vmin currentColor;
  }
  .background span:nth-child(10) {
    color: #fcfaff;
    top: 23%;
    left: 24%;
    animation-duration: 86s;
    animation-delay: -47s;
    transform-origin: 21vw -24vh;
    box-shadow: 4vmin 0 0.9307965675048031vmin currentColor;
  }
  .background span:nth-child(11) {
    color: #fcfaff;
    top: 27%;
    left: 36%;
    animation-duration: 101s;
    animation-delay: -257s;
    transform-origin: 20vw -5vh;
    box-shadow: 4vmin 0 1.0163589725209756vmin currentColor;
  }
  .background span:nth-child(12) {
    color: #a6a6a6;
    top: 91%;
    left: 100%;
    animation-duration: 101s;
    animation-delay: -115s;
    transform-origin: -2vw 17vh;
    box-shadow: 4vmin 0 1.1698423667083098vmin currentColor;
  }
  .background span:nth-child(13) {
    color: #a6a6a6;
    top: 43%;
    left: 88%;
    animation-duration: 263s;
    animation-delay: -257s;
    transform-origin: 13vw 23vh;
    box-shadow: -4vmin 0 0.5615592307224369vmin currentColor;
  }
  .background span:nth-child(14) {
    color: #a6a6a6;
    top: 51%;
    left: 82%;
    animation-duration: 243s;
    animation-delay: -252s;
    transform-origin: -24vw -12vh;
    box-shadow: 4vmin 0 0.8891790336824792vmin currentColor;
  }
  .background span:nth-child(15) {
    color: #fcfaff;
    top: 94%;
    left: 90%;
    animation-duration: 8s;
    animation-delay: -57s;
    transform-origin: 3vw 21vh;
    box-shadow: 4vmin 0 0.8172341827647291vmin currentColor;
  }
  .background span:nth-child(16) {
    color: #a6a6a6;
    top: 20%;
    left: 64%;
    animation-duration: 182s;
    animation-delay: -48s;
    transform-origin: 1vw 18vh;
    box-shadow: 4vmin 0 0.9961652928909184vmin currentColor;
  }
  .background span:nth-child(17) {
    color: #a6a6a6;
    top: 22%;
    left: 97%;
    animation-duration: 189s;
    animation-delay: -44s;
    transform-origin: 8vw -3vh;
    box-shadow: 4vmin 0 1.2759906134135566vmin currentColor;
  }
  .background span:nth-child(18) {
    color: #fcfaff;
    top: 70%;
    left: 81%;
    animation-duration: 217s;
    animation-delay: -30s;
    transform-origin: 4vw 11vh;
    box-shadow: -4vmin 0 1.008305745837738vmin currentColor;
  }
  .background span:nth-child(19) {
    color: #a6a6a6;
    top: 29%;
    left: 64%;
    animation-duration: 188s;
    animation-delay: -56s;
    transform-origin: -15vw 2vh;
    box-shadow: 4vmin 0 0.8726876861476836vmin currentColor;
  }
  .background span:nth-child(20) {
    color: #fcfaff;
    top: 11%;
    left: 91%;
    animation-duration: 157s;
    animation-delay: -101s;
    transform-origin: 4vw 4vh;
    box-shadow: -4vmin 0 0.5257335904279699vmin currentColor;
  }
  .background span:nth-child(21) {
    color: #d6d6d6;
    top: 69%;
    left: 85%;
    animation-duration: 104s;
    animation-delay: -125s;
    transform-origin: -16vw 14vh;
    box-shadow: -4vmin 0 0.778183303534135vmin currentColor;
  }
  .background span:nth-child(22) {
    color: #a6a6a6;
    top: 42%;
    left: 20%;
    animation-duration: 239s;
    animation-delay: -221s;
    transform-origin: -4vw -20vh;
    box-shadow: 4vmin 0 1.365733580112483vmin currentColor;
  }
  .background span:nth-child(23) {
    color: #d6d6d6;
    top: 96%;
    left: 99%;
    animation-duration: 169s;
    animation-delay: -93s;
    transform-origin: 11vw -16vh;
    box-shadow: 4vmin 0 1.1147967587657812vmin currentColor;
  }
  .background span:nth-child(24) {
    color: #fcfaff;
    top: 50%;
    left: 16%;
    animation-duration: 116s;
    animation-delay: -88s;
    transform-origin: -7vw -21vh;
    box-shadow: -4vmin 0 0.7865875962114053vmin currentColor;
  }
  .background span:nth-child(25) {
    color: #d6d6d6;
    top: 22%;
    left: 36%;
    animation-duration: 33s;
    animation-delay: -202s;
    transform-origin: 21vw 14vh;
    box-shadow: 4vmin 0 0.772015604354451vmin currentColor;
  }
  .background span:nth-child(26) {
    color: #a6a6a6;
    top: 28%;
    left: 37%;
    animation-duration: 48s;
    animation-delay: -209s;
    transform-origin: 12vw -7vh;
    box-shadow: -4vmin 0 1.0083506572166867vmin currentColor;
  }
  .background span:nth-child(27) {
    color: #a6a6a6;
    top: 34%;
    left: 58%;
    animation-duration: 164s;
    animation-delay: -169s;
    transform-origin: -13vw -9vh;
    box-shadow: -4vmin 0 1.2663845650449872vmin currentColor;
  }
  .background span:nth-child(28) {
    color: #fcfaff;
    top: 35%;
    left: 52%;
    animation-duration: 156s;
    animation-delay: -240s;
    transform-origin: -14vw 18vh;
    box-shadow: 4vmin 0 0.711275322092999vmin currentColor;
  }
  .background span:nth-child(29) {
    color: #d6d6d6;
    top: 4%;
    left: 76%;
    animation-duration: 19s;
    animation-delay: -119s;
    transform-origin: 20vw 4vh;
    box-shadow: -4vmin 0 1.0059628427593137vmin currentColor;
  }
  